import { PaymentVariant } from "./payments";

// Milliseconds
const SECOND = 1000;
const MINUTE = 60 * SECOND;
export const SEGMENT_REDIRECT_TIMEOUT_MS = SECOND / 2;
export const BANK_AUTH_POLLING_INTERVAL = 2 * SECOND;
export const TIMEOUT_PERIOD = 5 * MINUTE;
export const REDIRECT_WAIT_PERIOD = 4 * SECOND;
export const POST_AUTH_MAX_WAIT_PERIOD = 5 * SECOND;
export const POST_AUTH_MIN_WAIT_PERIOD = 2 * SECOND;
export const TMX_TRACKING_WAIT_PERIOD = 3 * SECOND;
export const GC_SUPPORT_EMAIL = "help@gocardless.com";

export const DEFAULT_COUNTRY_CODE = "GB";
export const MIN_MATCHED_INSTITUTION_LIMIT = 5;
export const MAX_INSTITUTIONS_TO_SHOW = 14;
export const MIN_INSTITUTION_FILTER_LENGTH = 2;
export const BRF_E_VARIANT_EXPERIMENT = "brf_e_variant_experiment";
export const INSTANT_PAY_VARIANTS = [
  PaymentVariant.InstantBankPay,
  PaymentVariant.DualFlow,
  PaymentVariant.VariableRecurringPaymentsWithFirstPayment,
];

export enum BANK_AUTHORISATION_FLOW_TYPE {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  DESKTOP_TO_MOBILE = "desktop_to_mobile",
}
